import { DesignSystemComponent, StackT } from "@/types/design-system-types";
import ComponentResolver from "@/utils/system/component-resolver";
import { cn } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";

const Stack = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  // Contentful controlled Props
  slots = data?.fields.slots || [],
  theme = data?.fields.theme,

  // Internal props
  className,
  ...props
}: DesignSystemComponent<StackT>) => {
  try {
    return (
      <div {...props} className={cn("relative h-min", [className])}>
        {/* DEBUGGING INFO FOR LOCAL */}
        {debug}

        {/* Slots */}
        {slots?.length && (
          <div className={cn("flex h-full w-full flex-col gap-4")}>
            {slots.map((item: any, index: number) => (
              <ComponentResolver
                className="flex-1"
                key={index}
                data={item}
                theme={theme}
              />
            ))}
          </div>
        )}
      </div>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Stack;

// xl:w-1/3 sm:w-1/3 sm:w-1/2 xl:w-1/2 xl:w-1/4 sm:w-1/4 sm:w-full
